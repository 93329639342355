import React from "react";
import { Row, Col, Form, Input } from 'antd';
import './loginPage.style.scss';

const LoginPage = () => {

  const onSubmit = () => {
    document.getElementById("logonform").submit();
  }

  return (
    <div className="login-page white-bg">
      <div className="page-title-section">
        <div className="page-title-1">
          Online Planning
        </div>
        <div className="page-title-2">
          Client Login
        </div>
      </div>
      <Row justify="center">
        <Col xs={22} md={18} lg={12} xl={10}>
          <div className="contact-box">
            <Form  layout="vertical" id="logonform" action="https://djeventplanning.info/clientlogon.asp?djidnumber=10301" method="post" onFinish={onSubmit}>
              
              <div className="form-label">Username</div>
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Username is required!' }]}
              >
                <Input size="large" name="username"/>
              </Form.Item>

              <div className="form-label">Password</div>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Password is required!' }]}
              >
                <Input size="large" name="password" type="password"/>
              </Form.Item>

              <Row align="middle">
                <Col xs={12} md={12}>
                  <button className="content-section-button" type="submit" style={{ width: 150, marginTop: 10 }}>Login</button>
                </Col>
                <Col xs={12} md={12} style={{ textAlign: "right" }}>
                  <a href="https://djeventplanning.info/sendpassword.asp?typeoflogon=client" target="blank">Forgot Your Password?</a>
                </Col>
              </Row>
              
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

LoginPage.propTypes = {};

export default LoginPage;
