import React, { useEffect } from "react";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import dancefloorLightingImage from '../../images/dancefloor-lighting.jpg';
import uplightingImage from '../../images/uplighting.png';
import './enhancementsPage.style.scss';

const EnhancementsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="page-title-section">
          <div className="page-title-1">
            Show stopping
          </div>
          <div className="page-title-2">
            Enhancements
          </div>
        </div>
        <ResponsiveContainer className="gray-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <h2>Uplighting</h2>
              <div className="content-section-text">
                An easy way to transform a room is through lighting. In addition to setting the mood, it also makes for some incredible pictures! My uplighting package consists of high powered LED lights that cover the walls all the way around the room. They can be set to any color you choose. During dancing, we program the lights to change colors to the music in order to bring energy to the party.
              </div>
            </Col>
            <Col xs={24} md={12}>
              <img src={uplightingImage} alt="Uplighting"/>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="white-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <img src={dancefloorLightingImage} alt="Dance Floor Lighting"/>
            </Col>
            <Col xs={24} md={12}>
              <h2>Dance Floor Lighting</h2>
              <div className="content-section-text">
                At your request, I can provide a simple lighting system that lights up the dance floor. These lights can turn all white for slow dances and can change colors to the music during high energy moments. These intelligent lights are controlled by a computer which makes it easy to change scenes quickly and easily. This is another great way to create a fun atmosphere!
              </div>
            </Col>
          </Row>
        </ResponsiveContainer>
    </div>
  );
}

EnhancementsPage.propTypes = {};

export default EnhancementsPage;
