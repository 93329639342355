import { get, post } from ".";

// Auth
export const login = (body) => post('/login', body);
export const logout = (body) => post('/logout', body);

// Prospects
export const getProspects = () => get('/prospects');

// Contact Form
export const getWebsiteForm = (id) => get(`/website/form/${id}`);
export const submitWebsiteForm = (id, body) => post(`/website/form/${id}`, body);
export const submitContactForm = (body) => post(`/website/contact`, body);