import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Layout, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import NavMenu from '../navMenu';
import logo from '../../images/tristardj-logo-color.jpg';
import './header.style.scss';

const Header = props => {
  const [visible, setVisible] = useState(false);

  return (
    <Layout.Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <Link to="/" className="logo">
        <img src={logo} alt="TriStar DJ Logo"/>
      </Link>
      <NavMenu/>
      <div className="menu-icon">
        <button onClick={() => setVisible(true)}><MenuOutlined/></button>
      </div>
      <Drawer
        title="Menu"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ padding: 0 }}
      >
        <NavMenu isMobile={true} onClose={() => setVisible(false)}/>
      </Drawer>
    </Layout.Header>
  );
};

export default withRouter(Header);
