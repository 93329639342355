import React ,{ useEffect } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import socialBg from '../../images/incredible_parties.png';
import socialDetailBg from '../../images/social-detail.jpg';
import './socialPage.style.scss';

const SocialPage = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderContent = () => {
    return (
      <div>
        <div className="page-title-section">
          <div className="page-title-1">
            Incredible
          </div>
          <div className="page-title-2">
            Parties
          </div>
        </div>
        <div className="page-image-section">
          <img src={socialBg} alt="Social Events"/>
        </div>
        <ResponsiveContainer className="gray-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <h2>Party Time</h2>
              <div className="content-section-text">
                So... you are throwing a party, and you want it to be epic.  I don't blame you.  Life is too short to be throwing lame events.  This is where I can help.  I became a DJ because I love seeing people break out of their comfort zone and have fun.
              </div>
              <div className="content-section-text" style={{ marginTop: 10 }}>
                Whatever you are organizing, I can work with you to create a party your guests will never forget. I have done everything from campus-wide college parties to 5K runs with thousands of people.
              </div>
            </Col>
            <Col xs={24} md={12}>
              <img src={socialDetailBg} alt="Party Time"/>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="action-section blue-bg">
          <h2>What are you waiting for?</h2>
          <button className="white-outline-button mt-20" onClick={() => props.history.push("/contact")}>Request a Quote</button>
        </ResponsiveContainer>
      </div>
    )
  }

  return (
    <div className="page-content">
      {renderContent()}
    </div>
  );
}

SocialPage.propTypes = {
  match: PropTypes.object
};

export default withRouter(SocialPage);
