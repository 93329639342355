import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { formatPhoneNumber } from 'react-phone-number-input'
import { submitContactForm } from '../../api/api';
import { Row, Col, Form, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import './contactPage.style.scss';
import { renderInputField, renderDateField, renderEmailField, renderTextAreaField, renderPhoneField, renderSelectField } from '../../components/formFields';

const ContactPage = () => {
  const history = useHistory();

  const eventTypeData = [
    {
      text: "Wedding",
      value: "Wedding"
    },
    {
      text: "Corporate Event",
      value: "Corporate Event"
    },
    {
      text: "Church Event",
      value: "Church Event"
    },
    {
      text: "School Dance",
      value: "School Dance"
    },
    {
      text: "Birthday Party",
      value: "Birthday Party"
    },
    {
      text: "Other",
      value: "Other"
    },
  ]

  const contactTypeData = [
    {
      text: "Bride",
      value: "Bride"
    },
    {
      text: "Groom",
      value: "Groom"
    },
    {
      text: "Mother of the Bride",
      value: "Mother of the Bride"
    },
    {
      text: "Father of the Bride",
      value: "Father of the Bride"
    },
    {
      text: "Wedding Planner",
      value: "Wedding Planner"
    }
    ,
    {
      text: "Other",
      value: "Other"
    }  
  ]

  const [isLoading, setLoading] = useState(false);
  const [eventType, setEventType] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = values => {
    
    const body = {
      ...values,
      'phone': formatPhoneNumber(values['phone']),
      'event_date': values['event_date'].format('YYYY-MM-DD'),
      'event_date_long': values['event_date'].format('MMMM DD, YYYY (dddd)')
    }

    submitContactForm(body).then(response => {
      displaySuccess()
    })
  }

  const getParsedPhoneNumber = (value) => {
    return value ? value.replace("+1","") : "";
  }

  const displaySuccess = () => {
    Modal.success({
      content: 'Thank you!',
      onOk: afterSuccess
    });
  }

  const displayError = () => {
    setLoading(false)
    Modal.error({
      content: 'Please try again later.',
      onOk: () => history.push("/")
    });
  }

  const afterSuccess = () => {
    history.push("/");
  }

  const onChangeEventType = (value) => {
    setEventType(value)
  }

  const renderContactType = () => {
    if (eventType == "Wedding") {
      return renderSelectField("I am the...", "contact_type", "", contactTypeData)
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <div className="loading-spinner-container"><LoadingOutlined style={{ fontSize: 40 }}/></div>
    } else {
      return (
        <>
          <div className="page-title-section">
            <div className="page-title-1">
              Need a quote?
            </div>
            <div className="page-title-2">
              Contact us
            </div>
          </div>
          <Row justify="center">
            <Col xs={22} md={18} lg={12} xl={10}>
              <div className="contact-box">
                <Form  layout="vertical" name="contact" onFinish={onSubmit}>
                  { renderSelectField("Event Type", "event_type", "", eventTypeData, onChangeEventType) }
                  { renderContactType() }
                  { renderInputField("First Name", "first_name", "50%", true) }
                  { renderInputField("Last Name", "last_name", "50%", true) }
                  { renderEmailField("Email", "email", "100%", true) }
                  { renderPhoneField("Phone", "phone", "200px", true) }
                  { renderDateField("Event Date", "event_date", "150px", true) }
                  { renderInputField("Venue", "venue", "100%", true) }
                  { renderTextAreaField("Tell us about your event!", "event_description", "100%", true)}
                  { renderInputField("Who can we thank for your referral?", "referral_source", "100%", true) }
                  <button className="content-section-button" type="submit">Submit</button>
                </Form>
              </div>
            </Col>
          </Row>
        </>
      )
    }
  }

  return (
    <div className="contact-page white-bg">
      { renderContent() }
    </div>
  );
}

ContactPage.propTypes = {};

export default ContactPage;
