import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Modal } from 'antd';
import './contractPage.style.scss';
import { renderInputField, renderDateField, renderEmailField, renderPhoneField } from '../../components/formFields';

const ContractPage = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = values => {

    const body = {
      ...values,
      'event_type_name': values["event_type_id"].label,
      'event_type_id': values["event_type_id"].key,
      'contact_type_name': values["contact_type_id"].label,
      'contact_type_id': values["contact_type_id"].key,
      'referral_source_name': values["referral_source_id"].label,
      'referral_source_id': values["referral_source_id"].key,
      'event_date': values['event_date'].format('YYYY-MM-DD'),
      'event_date_long': values['event_date'].format('MMMM DD, YYYY (dddd)')
    }

    // submitWebsiteForm(formId, body).then(response => {
    //   displaySuccess()
    // })
  }

  const displaySuccess = () => {
    Modal.success({
      content: 'Thank you!',
      onOk: afterSuccess
    });
  }

  const displayError = () => {
    setLoading(false)
    Modal.error({
      content: 'Please try again later.',
      onOk: () => history.push("/")
    });
  }

  const afterSuccess = () => {
    history.push("/");
  }

  const renderContent = () => {
    return (
      <>
        <div className="page-title-section">
          <div className="page-title-1">
            Request a
          </div>
          <div className="page-title-2">
            Contract
          </div>
        </div>
        <Row justify="center">
          <Col xs={22} md={18} lg={12} xl={10}>
            <div className="contact-box">
              <Form  layout="vertical" name="contact" onFinish={onSubmit}>
                { renderInputField("Bride's First Name", 'bride_first_name', '50%', true)}
                { renderInputField("Bride's Last Name", 'bride_last_name', '50%', true)}
                { renderPhoneField("Bride's Phone", 'bride_phone', '200px', true)}
                { renderEmailField("Bride's Email", 'bride_email', '50%', true)}
                { renderInputField("Groom's First Name", 'groom_first_name', '50%', true)}
                { renderInputField("Groom's Last Name", 'groom_last_name', '50%', true)}
                { renderPhoneField("Groom's Phone", 'groom_last_name', '200px', true)}
                { renderEmailField("Groom's Email", 'groom_last_name', '50%', true)}
                { renderDateField("Wedding Date", 'event_date', '50%', true)}
                <button className="content-section-button" type="submit">Submit</button>
              </Form>
            </div>
          </Col>
        </Row>
      </>
    )

  }

  return (
    <div className="contact-page white-bg">
      { renderContent() }
    </div>
  );
}

ContractPage.propTypes = {};

export default ContractPage;
