import React ,{ useEffect } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import churchBg from '../../images/church-main.jpg';
import churchDetailBg from '../../images/church-detail.jpg';
import './churchPage.style.scss';

const ChurchPage = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderContent = () => {
    return (
      <div>
        <div className="page-title-section">
          <div className="page-title-1">
            Church
          </div>
          <div className="page-title-2">
            Events
          </div>
        </div>
        <div className="page-image-section">
          <img src={churchBg} alt="Church Events"/>
        </div>
        <ResponsiveContainer className="gray-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <h2>Good, Clean Fun</h2>
              <div className="content-section-text">
                Whether you are organizing an outreach event, youth function, or dance party for your church, I can work with you to create a fun, clean atmosphere that everyone will enjoy.
              </div>
              <div className="content-section-text" style={{ marginTop: 10 }}>
                As a Christian, I know it can be hard to find entertainment that’s appropriate for church settings. A lot of DJ’s don’t understand the concept of “good, clean fun”.  I can provide everything from upbeat Christian music for an outdoor event to clean music for a dance party.
              </div>
            </Col>
            <Col xs={24} md={12}>
              <img src={churchDetailBg} alt="Good, Clean Fun"/>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="action-section blue-bg">
          <h2>What are you waiting for?</h2>
          <button className="white-outline-button mt-20" onClick={() => props.history.push("/contact")}>Request a Quote</button>
        </ResponsiveContainer>
      </div>
    )
  }

  return (
    <div className="page-content">
      {renderContent()}
    </div>
  );
}

ChurchPage.propTypes = {
  match: PropTypes.object
};

export default withRouter(ChurchPage);
