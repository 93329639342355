import React from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import AdminLayout from '../components/adminLayout';
import SiteLayout from '../components/siteLayout';
import { startsWith } from 'lodash';

import HomePage from "../pages/homePage";
import WeddingsPage from "../pages/weddingsPage";
import EventsPage from "../pages/eventsPage";
import SocialPage from "../pages/socialPage";
import ChurchPage from "../pages/churchPage";
import CorporatePage from "../pages/corporatePage";
import EnhancementsPage from "../pages/enhancementsPage";
import MyBioPage from "../pages/myBioPage";
import MyProcessPage from "../pages/myProcessPage";
import EquipmentPage from "../pages/equipmentPage";
import WhyMePage from "../pages/whyMePage";
import FAQPage from "../pages/faqPage";
import ContactPage from "../pages/contactPage";
import ContractPage from "../pages/contractPage";
import LoginPage from "../pages/loginPage";

const Routes = (props) => {

  const redirectObject = (path, props) => {
    return {
      pathname: path,
      state: {
        from: props.location
      }
    };
  };

  const renderAdminLayout = () => {
    return (
      <AdminLayout>
        {renderRoutes()}
      </AdminLayout>
    )
  }

  const renderSiteLayout = () => {
    return (
      <SiteLayout>
        { renderRoutes() }
      </SiteLayout>
    )
  }

  const renderRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/weddings" component={WeddingsPage} />
        <Route exact path="/events" component={EventsPage} />
        <Route exact path="/events/social" component={SocialPage} />
        <Route exact path="/events/church" component={ChurchPage} />
        <Route exact path="/events/corporate" component={CorporatePage} />
        <Route exact path="/enhancements" component={EnhancementsPage} />
        <Route exact path="/about/bio" component={MyBioPage} />
        <Route exact path="/about/process" component={MyProcessPage} />
        <Route exact path="/about/equipment" component={EquipmentPage} />
        <Route exact path="/about/why" component={WhyMePage} />
        <Route exact path="/about/faqs" component={FAQPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/contract" component={ContractPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route
          path="*"
          render={props => {
            return <Redirect to={redirectObject("/", props)} />;
          }}
        />
    </Switch>
    )
  }

  const renderLayout = () => {
    if (startsWith(props.location.pathname, '/admin/login')) {
      return renderRoutes();
    } else if (startsWith(props.location.pathname, '/admin')) {
      return renderAdminLayout();
    } else {
      return renderSiteLayout();
    }
  }

  return renderLayout();
}

export default withRouter(Routes);
