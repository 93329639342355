import React, { useMemo } from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './history';
import AppContext from './context';
import usePersistedStateObject from '../hooks/usePersistedStateObject';
import StorageKeys from '../constants/storageKeys';

const App = () => {

  const [auth, setAuth] = usePersistedStateObject(StorageKeys.AUTH);

  const value = useMemo(() => ({
    auth,
    setAuth
  }), [auth])

  return (
    <AppContext.Provider value={value}>
      <Router history={history}>
        <Routes/>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
