import React, { useEffect } from "react";
import { Row, Col, Collapse } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import faqPic from '../../images/faqs.jpg';
import './faqPage.style.scss';

const FAQPage = () => {

  const { Panel } = Collapse;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="faq-page">
      <ResponsiveContainer className="white-bg">
        <Row align="top" className="content-section">
          <Col xs={24} md={12} lg={14}>
            <h2 style={{ marginBottom: 30 }}>Frequently Asked Questions</h2>
            <Collapse accordion>
              <Panel header="Do you travel outside of the Dallas/Fort Worth area?" key="1">
                <div>Yes. I have have done events in Houston, Austin, and even some out of state. My travel expenses are included in your customized quote. If I am available to travel on the day of your event, I would love to serve you!</div>
              </Panel>
              <Panel header="Do you bring an assistant?" key="2">
                <div>Yes, I will always have someone with me to help out. If you need anything at all during your event, one of us will take care of it for you.</div>
              </Panel>
              <Panel header="What type of music will you play at my event?" key="3">
                <div>It is important to me that I play the music you love. During the planning process, you will have the opportunity to let me know what types of songs you would like to be played during your event. Using my online tools, you will be able to create a “Must Play”, "Play if Possible", and a “Do Not Play” list. If you despise a certain genre or artist, I won’t play it! If you request a song that I do not currently have in my database, I will get it for you!</div>
              </Panel>
              <Panel header="How many songs do you have?" key="4">
                <div>I have over 30,000 songs and counting. Every month my database is updated with the most current music of all genres. I have a wide variety of music, including Top 40s, Country, Oldies, Jazz, and Slow Dance.</div>
              </Panel>
              <Panel header="What type of equipment do you use?" key="5">
                <div>I only use professional sound equipment for my events. This includes QSC speakers, Sennheiser microphones, and Denon mixers. I also use a tabletop facade to hide all of my equipment and cords.</div>
              </Panel>
              <Panel header="Do you bring backup equipment?" key="6">
                <div>Yes, I come prepared to handle anything that comes my way. This includes a smaller sound system and an extra laptop that can be used in case anything happens to my main system. No matter what challenges I might face, the music will keep playing!</div>
              </Panel>
              <Panel header="When will you arrive to start setting up for my event?" key="7">
                <div>I will arrive at least 3 hours before your event to start setting up. This allows us plenty of time to have everything perfect before guests arrive.</div>
              </Panel>
              <Panel header="Are you insured?" key="8">
                <div>Yes, I have full liability insurance. Some venues require vendors to have this, so I have you covered!</div>
              </Panel>
            </Collapse>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <img src={faqPic} alt="FAQs"/>
          </Col>
        </Row>
      </ResponsiveContainer>
    </div>
  );
}

FAQPage.propTypes = {};

export default FAQPage;
