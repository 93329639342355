import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Row, Col, Carousel } from "antd";
import whiteLogo from '../../images/tristardj-logo-white.png';
import './homePage.style.scss';

const HomePage = props => {

  const renderContent = () => {
    return (
      <div className="">
        <Carousel autoplay autoplaySpeed={5000} effect="fade">
          <div className="slider-image image-1">
            <div className="slider-image-overlay">
              <img src={whiteLogo} className="slider-image-logo" alt="TriStar DJ"/>
              <div className="slider-image-text">
                Creating events that people <br/> can't stop talking about.
              </div>
              <button className="slider-image-button" onClick={() => props.history.push('/weddings')}>
                LEARN MORE
              </button>
            </div>
          </div>
          <div className="slider-image image-2">
            <div className="slider-image-overlay">
              <div className="slider-image-title">
                Unforgettable <br/>weddings
              </div>
              <div className="slider-image-text">
                Making memories that will <br/> last a lifetime.
              </div>
              <button className="slider-image-button" onClick={() => props.history.push('/weddings')}>
                LEARN MORE
              </button>
            </div>
          </div>
          <div className="slider-image image-3">
            <div className="slider-image-overlay">
              <div className="slider-image-title">
                Incredible Parties
              </div>
              <div className="slider-image-text">
                Bringing energy to the <br/> dance floor.
              </div>
              <button className="slider-image-button" onClick={() => props.history.push('/events/social')}>
                LEARN MORE
              </button>
            </div>
          </div>
        </Carousel>
        <Row justify="center" className="action-section white-bg">
          <Col xs={24} lg={20} xl={16}>
            <h2>Change the way you think about a wedding DJ</h2>
            <div className="action-section-text">
              Some people view a wedding DJ as someone who simply plays music. Although music is a big part of what I do, I consider myself much more than just a DJ. When hiring me for your wedding, you are also getting a planning partner, coordinator, master of ceremonies, and most importantly... a friend. I want the best for you and will help you create a fun celebration that fits your personality.
            </div>
            <button className="black-outline-button mt-20" onClick={() => props.history.push('/weddings')}>Learn More</button>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className="page-content">
      {renderContent()}
    </div>
  );
}

HomePage.propTypes = {
  match: PropTypes.object
};

export default withRouter(HomePage);
