import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import myProcessPic from '../../images/my-process.jpg';
import './myProcessPage.style.scss';

const MyProcessPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="my-process-page">
      <ResponsiveContainer className="white-bg">
        <Row align="top" className="content-section">
          <Col xs={24} md={12} lg={14}>
            <h2>My Process</h2>
            <div className="content-section-text">
              I firmly believe that you should feel comfortable with your decision when hiring a DJ. This is why I prefer meeting with you in person to determine whether or not I am the right fit for your wedding. Assuming we become best friends, the booking process is pretty simple. Here are the steps of my process from start to finish:
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              <div className="my-process-section-title">
                1. Contact Me
              </div>
              Fill out my <Link to="/contact">contact form</Link> to recieve more information and a custom quote. I usually respond within 24 hours. During this step, you will have the opportunity to schedule a personal meeting with me to find out more about why I am so unique.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              <div className="my-process-section-title">
                2. Introduction Meeting
              </div>
              This is a laid back opportunity for you to ask questions and get to know my personality. I want to make sure we are the right fit for each other before you make your final decision. This is also a time for me to get to know you and explain how I can customize your wedding to fit your personality. I will walk you through the highlights of my services and explain why my company is different from the rest. There is no pressure to sign a contract during this time. Take the information home, meet with other DJs, and get back with me. If you would like to move forward with the process... awesome! If not, no hard feelings.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              <div className="my-process-section-title">
                3. Confirm
              </div>
              If you decide to hire TriStar DJ for your wedding entertainment, just call or send me an email letting me know that you would like a contract. I will email you a draft of the contract along with information on how to pay your deposit. After I receive your signed contract and deposit, you will be officially booked!
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              <div className="my-process-section-title">
                4. Online Planning
              </div>
              You will receive access to my online planning tools so that you can start planning your wedding from the comfort of your home. These tools will allow you to plan every aspect of your wedding (events, special dances, music, etc.) on your own time. Once you are finished, I will use the information you provide to help guide our planning meeting.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              <div className="my-process-section-title">
                5. Planning Meeting
              </div>
              I will contact you 20-30 days before your wedding to schedule a planning meeting. During this time, we will finalize all of the details of your wedding and come up with an order of events. The goal of this meeting is to answer any questions or concerns you may have. After this meeting, I will send you the final order of events for your approval.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              <div className="my-process-section-title">
                6. Relax
              </div>
              After all of the planning is done and you feel comfortable with everything, you can relax knowing that everything is under control. By this time, you will know what to expect on your wedding day and be able to sit back and enjoy the moment!
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              It's important that you find a DJ whose personality is the right fit for you. More than anything, I want you to find what you are looking for. If my services don't fit within your budget or I cannot offer what you need, I will do my best to help you find someone who is perfect for you. I have connections with other Dallas wedding vendors, and I’m always glad to offer recommendations.
            </div>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <img src={myProcessPic} alt="My Process"/>
          </Col>
        </Row>
      </ResponsiveContainer>
    </div>
  );
}

MyProcessPage.propTypes = {};

export default MyProcessPage;
