import React ,{ useEffect } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import corporateBg from '../../images/corporate-main.jpg';
import corporateDetailBg from '../../images/corporate-detail.jpg';
import './corporatePage.style.scss';

const CorporatePage = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderContent = () => {
    return (
      <div>
        <div className="page-title-section">
          <div className="page-title-1">
            Corporate
          </div>
          <div className="page-title-2">
            Events
          </div>
        </div>
        <div className="page-image-section">
          <img src={corporateBg} alt="Corporate Events"/>
        </div>
        <ResponsiveContainer className="gray-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <h2>Be the Rockstar</h2>
              <div className="content-section-text">
                Are you in charge of finding entertainment for your next company party? If so, my goal is to help you become known as the rockstar who planned “the best corporate event ever”.
              </div>
              <div className="content-section-text" style={{ marginTop: 10 }}>
                I specialize in reading the crowd and playing music that gets people dancing. I can help facilitate dance-offs, raffles, and other events through out your party.
              </div>
            </Col>
            <Col xs={24} md={12}>
              <img src={corporateDetailBg} alt="Be the Rockstar"/>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="action-section blue-bg">
          <h2>What are you waiting for?</h2>
          <button className="white-outline-button mt-20" onClick={() => props.history.push("/contact")}>Request a Quote</button>
        </ResponsiveContainer>
      </div>
    )
  }

  return (
    <div className="page-content">
      {renderContent()}
    </div>
  );
}

CorporatePage.propTypes = {
  match: PropTypes.object
};

export default withRouter(CorporatePage);
