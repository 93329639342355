import React ,{ useState, useEffect } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import weddingsPersonal from '../../images/weddings-personal.jpg';
import weddingsAtmosphere from '../../images/weddings-atmosphere.jpg';
import weddingsInvestment from '../../images/weddings-investment.png';
import weddingsReception from '../../images/weddings-reception.jpg';
import weddingsCeremony from '../../images/weddings-ceremony.jpg';
import weddingBg from '../../images/unforgettable_weddings.png';
import './weddingsPage.style.scss';

const WeddingsPage = (props) => {

  const [showCeremonyModal, setShowCeremonyModal] = useState(false);
  const [showReceptionModal, setShowReceptionModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleCloseCeremonyModal = () => {
    setShowCeremonyModal(false);
  }

  const handleCloseReceptionModal = () => {
    setShowReceptionModal(false);
  }

  const renderContent = () => {
    return (
      <div>
        <div className="page-title-section">
          <div className="page-title-1">
            Unforgettable
          </div>
          <div className="page-title-2">
            Weddings
          </div>
        </div>
        <div className="page-image-section">
          <img src={weddingBg} alt="The Personal Touch"/>
        </div>
        <ResponsiveContainer className="gray-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <h2>The Personal Touch</h2>
              <div className="content-section-text">
                Your wedding is a once in a lifetime event that deserves the best. It shouldn’t be boring or cliché. This is a chance for you to share your love story with your friends and family and to celebrate the beginning of a new life together with your loved one.
              </div>
              <div className="content-section-text" style={{ marginTop: 10 }}>
                Every couple is different, which is why I believe in tailor-made weddings. By getting to know you and your personality, I will help you create a fun reception that reflects who you are; an evening you will remember for the rest of your life.
              </div>
            </Col>
            <Col xs={24} md={12}>
              <img src={weddingsPersonal} alt="The Personal Touch"/>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="white-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <img src={weddingsAtmosphere} alt="The Atmosphere"/>
            </Col>
            <Col xs={24} md={12}>
              <h2>The Atmosphere</h2>
              <div className="content-section-text">
                I have a passion for what I do, and it shows in my work. I have found that when I get into the music and start having fun, guests seem to follow the lead. This comes natural to me because I love music. The way music and dancing brings people together is facinating to me. Reading the crowd and playing the right song at the right time are important keys to a successful party.
              </div>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="gray-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <h2>The Investment</h2>
              <div className="content-section-text">
                Studies have shown that over 80% of guests remember the entertainment more than anything else at a wedding. This is why it's so important to do your research and find a quality DJ who is known for creating fun wedding receptions. It's an investment into the success of your wedding.
              </div>
            </Col>
            <Col xs={24} md={12}>
              <img src={weddingsInvestment} alt="The Investment"/>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="action-section blue-bg">
          <h2>My Process</h2>
          <div className="action-section-text">
            I firmly believe that you should feel comfortable with your decision when hiring a DJ. This is why I prefer meeting with you in person to determine whether or not I am the right fit for your wedding. Assuming we become best friends, the booking process is pretty simple. Click on the button below to see the steps start to finish.
          </div>
          <button className="white-outline-button mt-20" onClick={() => props.history.push("/about/process")}>Let's Go!</button>
        </ResponsiveContainer>
        <ResponsiveContainer className="gray-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <img src={weddingsReception} alt="The Atmosphere"/>
            </Col>
            <Col xs={24} md={12}>
              <h2>Reception Package</h2>
              <div className="content-section-text">
                When you hire me for your reception, I will provide everything you need to have a successful party. Whether you need an extra speaker in the lobby for cocktail hour or a few microphones for a special performance with your bridesmaids, I've got you covered.
              </div>
              <button className="content-section-button md-p-20" onClick={() => setShowReceptionModal(true)}>What's included?</button>
            </Col>
          </Row>
        </ResponsiveContainer>
        <ResponsiveContainer className="white-bg">
          <Row align="middle" className="content-section">
            <Col xs={24} md={12}>
              <h2>Ceremony Package</h2>
              <div className="content-section-text">
                In addition to providing entertainment for your reception, I can also provide a sound system for your ceremony. Whether it's in the same room, down the hall, or outside under the trees, I can supply everything you need to make your ceremony sound great.
              </div>
              <button className="content-section-button md-p-20" onClick={() => setShowCeremonyModal(true)}>What's included?</button>
            </Col>
            <Col xs={24} md={12}>
              <img src={weddingsCeremony} alt="The Investment"/>
            </Col>
          </Row>
        </ResponsiveContainer>
        <Modal
          title="Ceremony Package"
          visible={showCeremonyModal}
          onCancel={handleCloseCeremonyModal}
          footer={[
            <Button key="back" onClick={handleCloseCeremonyModal}>
              Close
            </Button>
          ]}
        >
          <p>My ceremony package can always be customized, however here are a few things that come standard:</p>
          <p>
            <div className="modal-section-title">Professional Sound System</div>
            <div className="modal-section-text">
              I bring nothing but quality equipment to your wedding. I will provide a system that sounds great so that all of your guests can hear everything that is going on.
            </div>
            <div className="modal-section-title">Microphones</div>
            <div className="modal-section-text">
              I will provide a clip-on microphone for your officiant as well as a handheld mic for anyone who might be speaking, singing, or reading a passage.
            </div>
            <div className="modal-section-title">Instrument Connections</div>
            <div className="modal-section-text">
              If you plan on having someone play guitar, keyboard, or any other instrument during your ceremony, I can hook them up to my system.
            </div>
            <div className="modal-section-title">Music</div>
            <div className="modal-section-text">
              I can play any songs you would like during your ceremony, whether it be the traditional "Wedding March" or the next big hit by Taylor Swift. I will work with you to create your own custom wedding soundtrack.
            </div>
            <div className="modal-section-title">Sound Guy</div>
            <div className="modal-section-text">
              I will actually be there to run the sound for you.
            </div>
          </p>
        </Modal>
        <Modal
          title="Reception Package"
          visible={showReceptionModal}
          onCancel={handleCloseReceptionModal}
          footer={[
            <Button key="back" onClick={handleCloseReceptionModal}>
              Close
            </Button>
          ]}
        >
          <p>My reception package can always be customized, however here are a few things that come standard:</p>
          <p>
            <div className="modal-section-title">Unlimited Hours of Service</div>
            <div className="modal-section-text">
              Unlike other DJs, I don't charge by the hour. I will be at your wedding for as long as you need! No overtime fees.
            </div>
            <div className="modal-section-title">Online Planning</div>
            <div className="modal-section-text">
              As soon as your contract is signed, you will have access to start planning your wedding from the comfort of your home with my Online Planning Tools. This will allow you to build a playlist, create a timeline, and much more.
            </div>
            <div className="modal-section-title">Personal Guidance</div>
            <div className="modal-section-text">
              I am here to be a resource for you. If you need advice, have any questions, or simply need someone to brainstorm with, I am only a phone call or email away.
            </div>
            <div className="modal-section-title">Planning Meetings</div>
            <div className="modal-section-text">
              I will meet with you in person to finalize all the details of your wedding. Using the information you provide during your online planning, we will come up with an order of events that flows smoothly.
            </div>
            <div className="modal-section-title">Professional Sound System</div>
            <div className="modal-section-text">
              I have some of the best equipment in the industry, which means that your reception will look and sound incredible.
            </div>
            <div className="modal-section-title">Dance Floor Lighting</div>
            <div className="modal-section-text">
              At your request, I can provide lighting that brings color to the dance floor. I believe in simple elegance, which means the lighting for your wedding will look classy and not like a high school prom. Just the right amount of dance floor lighting can help create a fun atmosphere.
            </div>
            <div className="modal-section-title">Large Music Library</div>
            <div className="modal-section-text">
              I have a huge database of over 30,000 songs from every genre. Whether you like hip hop, country, classical, or rock, I will have music to fit your needs. If by chance I do not have a song that you request, I will get it for you!
            </div>
            <div className="modal-section-title">Insurance</div>
            <div className="modal-section-text">
              Some venues require a proof of insurance from all vendors. I am fully insured, so I have you covered!
            </div>
          </p>
        </Modal>
      </div>
    )
  }

  return (
    <div className="page-content">
      {renderContent()}
    </div>
  );
}

WeddingsPage.propTypes = {
  match: PropTypes.object
};

export default withRouter(WeddingsPage);
