import React from "react";
import { withRouter } from 'react-router';
import { Menu } from 'antd';
import { isFunction, startsWith } from 'lodash';
import './navMenu.style.scss';

const NavMenu = (props) => {
  const { isMobile, onClose } = props;

  const onMenuClick = (key) => {
    isFunction(onClose) && onClose();
    props.history.push(key);
  }

  const isSelected = (path) => {
    return path === props.history.location.pathname ? "item-selected" : "";
  }

  const isSubMenuSelected = (path) => {
    return startsWith(props.history.location.pathname, path) ? "item-selected" : "";
  }

  return (
    <Menu mode={isMobile ? 'inline' : 'horizontal'} onClick={e => onMenuClick(e.key)}>
      <Menu.Item key={"/weddings"} className={isSelected("/weddings")}>WEDDINGS</Menu.Item>
      <Menu.SubMenu title={"EVENTS"} className={isSubMenuSelected("/events")}>
        <Menu.Item key={"/events/corporate"} className={isSelected("/events/corporate")}>CORPORATE PARTIES</Menu.Item>
        <Menu.Item key={"/events/church"} className={isSelected("/events/church")}>CHURCH EVENTS</Menu.Item>
        <Menu.Item key={"/events/social"} className={isSelected("/events/social")}>SOCIAL EVENTS</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={"/enhancements"} className={isSelected("/enhancements")}>ENHANCEMENTS</Menu.Item>
      <Menu.SubMenu title={"ABOUT"} className={isSubMenuSelected("/about")}>
        <Menu.Item key={"/about/bio"} className={isSelected("/about/bio")}>MY BIO</Menu.Item>
        <Menu.Item key={"/about/process"} className={isSelected("/about/process")}>MY PROCESS</Menu.Item>
        <Menu.Item key={"/about/why"} className={isSelected("/about/why")}>WHY ME?</Menu.Item>
        <Menu.Item key={"/about/faqs"} className={isSelected("/about/faqs")}>FAQS</Menu.Item>
        <Menu.Item key={"/about/equipment"} className={isSelected("/about/equipment")}>EQUIPMENT</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={"/contact"} className={isSubMenuSelected("/contact")}>CONTACT</Menu.Item>
      <Menu.Item key={"/login"} className={isSubMenuSelected("/login")}>LOGIN</Menu.Item>
    </Menu>
  );
}

export default withRouter(NavMenu);
