import React, { useEffect } from "react";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import microphonesPic from '../../images/microphones.jpg';
import speakersPic from '../../images/speakers.jpg';
import './equipmentPage.style.scss';

const EquipmentPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ResponsiveContainer className="white-bg">
        <Row align="top" className="content-section">
          <Col xs={24} md={12} lg={14}>
            <h2>Equipment</h2>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              A great DJ is defined by style and personality. Having nice equipment doesn't make you the best, however it is a way to increase the quality of your service. The equipment I bring to your wedding is some of the best professional gear on the market. I am always researching and investing in new technology in order to provide the best for my clients.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              Having quality microphones reduce the risk of interference and dropouts during your ceremony and reception. When I hand a mic to your maid of honor to give a toast, it simply has to work. We only have one opportunity to get it right, which is why I don't take any changes. If by some slim chance something goes wrong, a backup will be available in a matter of seconds.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              Music is a big part of a wedding, which is why it has to sound good. I use QSC K Series speakers and subwoofers which are perfect for all types of settings. During the elegant parts of your wedding, you will have a clean sound, yet when the party gets going, my system could rival the sound in most dance clubs. Multiple speakers can be placed throughout the room to make sure there is any even coverage.            
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              Although I trust my equipment, there is always a chance for technical difficulties. That is why I have built redundancy into my setup. I always have a second laptop with a copy of my music library ready to go. I also have a backup mixer, wireless microphone, and speakers. If something happens, I can have the party back up and running in no time.
            </div>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <img src={microphonesPic} alt="Microphones"/>
            <img src={speakersPic} alt="Speakers"/>
          </Col>
        </Row>
      </ResponsiveContainer>
    </div>
  );
}

EquipmentPage.propTypes = {};

export default EquipmentPage;
