import React from "react";
import PhoneInput from 'react-phone-number-input/input'
import { Form, Input, DatePicker, Checkbox, InputNumber, Select } from 'antd';

const widthStyle = (value) => {
  return {
    width: '100%',
    maxWidth: value
  }
}

const renderFormLabel = (name) => {
  return <div className="form-label">{name}</div>;
}

const renderInputField = (label, name, width, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        style={widthStyle(width)}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <Input size="large"/>
      </Form.Item>
    </>
  )
}

const renderDateField = (label, name, width, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        style={widthStyle(width)}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <DatePicker size="large" format="MM/DD/YYYY"/>
      </Form.Item>
    </>
  )
}

const renderCheckboxField = (label, name) => {
  return (
    <>
      <Form.Item
        name={name}
      >
        <Checkbox.Group>
          <Checkbox value={true}>{label}</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </>
  )
}

const renderCurrencyField = (label, name) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <InputNumber 
          size="large"
          style={widthStyle(200)}
          precision={2}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
      </Form.Item>
    </>
  )
}

const renderEmailField = (label, name, width, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        style={widthStyle(width)}
        rules={[
          { required: required, message: `${label} is required!` },
          { type: "email", message: `Please enter a valid email address` }
        ]}
      >
        <Input size="large"/>
      </Form.Item>
    </>
  )
}

const renderTextAreaField = (label, name, width, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        style={widthStyle(width)}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <Input.TextArea size="large" rows={4}/>
      </Form.Item>
    </>
  )
}

const renderPhoneField = (label, name, width, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        style={widthStyle(width)}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <PhoneInput 
          className="ant-input ant-input-lg"
          maxLength={14}
          onChange={() => {}}
          country="US"/>
      </Form.Item>
    </>
  )
}

const renderSelectOptions = (options) => {
  if (options) {
    return options.map((option) => (
      <Select.Option value={option.value} key={option.value}>{option.text}</Select.Option>
    ))
  }
  return null;
}

const renderSelectField = (label, name, placeholder, options, onChange = () => {}, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item 
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <Select
          placeholder={placeholder}
          allowClear
          size="large"
          onChange={onChange}
        >
          {renderSelectOptions(options)}
        </Select>
      </Form.Item>
    </>
  )
}

export { 
  renderInputField,
  renderDateField,
  renderCheckboxField,
  renderCurrencyField,
  renderEmailField,
  renderFormLabel,
  renderTextAreaField,
  renderPhoneField,
  renderSelectField
};