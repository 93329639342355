import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { Layout, Menu } from 'antd';
import AppContext from '../../app/context';
import './adminLayout.style.scss';

const { Header, Content, Sider } = Layout;

const AdminLayout = props => {

  const { setAuth } = useContext(AppContext);

  const onLogout = () => {
    setAuth({})
  }

  return (
    <Layout className="admin-layout">
      <Header className="header">
        <div className="logo" />
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" onClick={onLogout}>Logout</Menu.Item>
        </Menu>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item key="1" onClick={() => props.history.push("/admin")}>Dashboard</Menu.Item>
          </Menu>
        </Sider>
        <Layout className="admin-content-background">
          <Content
            className="admin-content-box"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default withRouter(AdminLayout);
