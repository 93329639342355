import React from 'react';
import { Row, Col } from 'antd';
import './responsiveContainer.style.scss';

const ResponsiveContainer = (props) => {
  const { className, children } = props;

  return (
    <Row justify="center" className={className}>
      <Col xs={24} lg={20} xl={16}>
        {children}
      </Col>
    </Row>
  );
};

export default ResponsiveContainer;
