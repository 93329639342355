import React, { useEffect } from "react";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import whyMePic from '../../images/why-me.jpg';
import './whyMePage.style.scss';

const WhyMePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="why-me-page">
      <ResponsiveContainer className="white-bg">
        <Row align="top" className="content-section">
          <Col xs={24} md={12} lg={14}>
            <h2>Why Me?</h2>
            <div className="content-section-text">
              I have a passion for being great at what I do. Your satisfaction is my number one priority, in fact, my reputation depends on it. If you are happy, I know you will tell your friends about me. It's a win-win situation!
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              I have seen my share of bad DJs, which is why I set out to be different. You should be relieved when you hire a DJ, not worried about what he might do to ruin the moment.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              I am much more than just a DJ. Whether you have a wedding planner or not, I love to be involved in the planning process. If you need ideas or help with the timeline, I am here to be a resource to you.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              I am not obnoxious on the microphone and will never embarrass you. I believe in moderate crowd participation in order to connect with guests and help direct the flow.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              I use some of the best sound and lighting equipment availabile, which means your wedding is going to look and sound great. I am always researching and investing in new technology in order to provide the best service possible.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              I make your wedding about YOU, not about me. This is your special day, and I am here to make you look good. Every wedding I do is different, all the way from the grand entrance to the last dance. By getting to know your personality and interests, I will work with you to create an event that reflects your personality.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              Lots of brides have recommended me because they have had such a good experience. I invite you to read their reviews on my WeddingWire page.
            </div>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <img src={whyMePic} alt="Why Me?"/>
          </Col>
        </Row>
      </ResponsiveContainer>
    </div>
  );
}

WhyMePage.propTypes = {};

export default WhyMePage;
