import React from 'react';
import { withRouter } from 'react-router';
import { Layout } from 'antd';
import SiteHeader from '../header';
import './siteLayout.style.scss';


const { Content, Footer } = Layout;

const AdminLayout = props => {
  let date = new Date();
  let year = date.getFullYear();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SiteHeader/>
      <Content className="site-layout" style={{ padding: '0', marginTop: 64 }}>
        <div className="site-layout-background" style={{ padding: 0 }}>
          {props.children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Copyright © {year} TriStar DJ</Footer>
    </Layout>
  );
};

export default withRouter(AdminLayout);
