import React from "react";
import { Row, Col, Card } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import churchEvents from '../../images/home_church.jpg';
import './eventsPage.style.scss';

const EventsPage = () => {
  return (
    <div className="events-page">
      <div className="page-title-section">
        <div className="page-title-1">
          Epic
        </div>
        <div className="page-title-2">
          Events
        </div>
      </div>
      <ResponsiveContainer className="white-bg">
        <Row>
          <Col xs={24} md={8}>
            <Card
              hoverable
              cover={<img alt="example" src={churchEvents} />}
            >
              <Card.Meta title="Corporate Parties"/>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card
              hoverable
              cover={<img alt="example" src={churchEvents} />}
            >
              <Card.Meta title="Church Events"/>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card
              hoverable
              cover={<img alt="example" src={churchEvents} />}
            >
              <Card.Meta title="Social Events"/>
            </Card>
          </Col>
        </Row>
      </ResponsiveContainer>
    </div>
  );
}

EventsPage.propTypes = {};

export default EventsPage;
