import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import ResponsiveContainer from '../../components/responsiveContainer';
import myBioPic from '../../images/my-bio.jpg';
import './myBioPage.style.scss';

const MyBioPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ResponsiveContainer className="white-bg">
        <Row align="top" className="content-section">
          <Col xs={24} md={12} lg={14}>
            <h2>My Bio</h2>
            <div className="content-section-text">
              I am and always will be an entrepreneur at heart. At age 14, I started my first business renting out bounce houses for parties, festivals, and other special events. This is one of the ways I made extra money throughout high school. A year later, I helped my parents start a family business selling sno cones. All of this experience has taught me the importance of offering quality service to people.            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              Becoming a professional DJ was something I have dreamed about since I was a kid. In high school, I borrowed some speakers and DJ'ed my first Sweet 16 party. I loved seeing the smile on everyone's face as they danced and knew this was something I wanted to continue doing.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              While attending Baylor University, I started DJ'ing for Greek events and campus-wide parties. My love for engaging people through music continued to grow with every new event. Towards the end of my college career, my friends started asking me to DJ their weddings. Little did I know this would lead to a whole new passion.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              Since graduating from Baylor and moving to Dallas in 2010, I have focused my efforts on providing entertainment for weddings. I have found that there is nothing more rewarding than seeing the look on a guy's face when he gets to dance with his wife for the first time. I love my job and feel honored to work with couples on one of the best days of their lives.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              You will never catch me announcing birthdays or playing the "Chicken Dance". I believe that a fun atmosphere can be created without the cheesy gimmicks. My goal is to make you look good and help you create memories that will last a lifetime.
            </div>
            <div className="content-section-text" style={{ marginTop: 20 }}>
              Feel free to <Link to="/contact">contact me</Link> if you have any questions at all. I would love the opportunity to visit with you about your wedding!
            </div>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <img src={myBioPic} alt="The Personal Touch"/>
          </Col>
        </Row>
      </ResponsiveContainer>
    </div>
  );
}

MyBioPage.propTypes = {};

export default MyBioPage;
